//This will be a page that lists out the different services we offer
//The page should be styled with a background image and a card for each service
//The card should have an image, a title, and a description
//The whole page should be mobile friendly using the Grid component

import React, { useEffect } from 'react'
import { Typography, Grid } from '@mui/material'

import {
    Box,
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    ImageList,
    ImageListItem,
} from '@mui/material'
import Button from '@mui/material/Button'
import FooterMap from '../../utils/Components/SharedOrPublicViews/Footer/FooterMap'

import backyard from '../../utils/images/backyard.jpeg'
import shed from '../../utils/images/shed.jpg'
import snow6 from '../../utils/images/generic_snow_removal/IMG_3492.jpeg'
import snow7 from '../../utils/images/generic_snow_removal/IMG_3498.jpeg'

import snow8 from '../../utils/images/generic_snow_removal/IMG_3509.jpeg'
import snow9 from '../../utils/images/generic_snow_removal/IMG_3371.jpeg'
import snow10 from '../../utils/images/generic_snow_removal/IMG_5870.jpeg'

import mulch from '../../utils/images/mulch.jpeg'
import sidewalk from '../../utils/images/sidewalk.jpeg'
import retaining_wall from '../../utils/images/retaining_wall.jpeg'

import arborvitae from '../../utils/images/arborvitae.jpeg'

import { useTheme } from '@mui/material/styles'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { AccessibilityNew } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export default function Services(props) {
    const theme = useTheme()
    const navigate = useNavigate()

    // Inside your component
    const location = useLocation()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
        })
    }, [location])

    const snowRemovalList2 = [
        {
            img: snow6,
            title: 'Image',
        },
        {
            img: snow7,
            title: 'Image',
        },
    ]

    const snowRemovalList = [
        {
            img: snow8,
            title: 'Image',
        },
        {
            img: snow9,
            title: 'Image',
        },
        {
            img: snow10,
            title: 'Image',
        },
    ]

    return (
        <Grid paddingTop={2} container width={'auto'}>
            <Helmet>
                <title>Services - All Round Contracting</title>
                <meta
                    name="description"
                    content="We offer general contracting, lawn care, snow removal, holiday lighting services and more. We are located in Inver Grove Heights, Minnesota."
                />
                <link
                    rel="canonical"
                    href="https://allroundcontractingllc.com/services"
                />

                <meta
                    property="og:title"
                    content="All Round Contracting - Services Offered"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://allroundcontractingllc.com/services"
                />
                <meta
                    property="og:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <meta
                    property="og:description"
                    content="We offer general contracting, lawn care, snow removal, holiday lighting services and more. We are located in Inver Grove Heights, Minnesota."
                />
                <meta
                    property="og:site_name"
                    content="All Round Contracting LLC"
                />
                <meta property="og:locale" content="en_US" />
            </Helmet>

            <Grid item xs={12} padding={2}>
                <Box width={'100%'}>
                    <Typography
                        variant="h1"
                        textAlign={'center'}
                        fontWeight={'bold'}
                        fontSize={'4rem'}
                        sx={{ overflowWrap: 'word-break' }}
                        overflow={'wrap'}
                        color={theme.palette.primary.textWhite}
                    >
                        Services
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bgcolor={theme.palette.primary.textWhite}
                    padding={2}
                >
                    <Typography textAlign={'center'} variant="h2" padding={1}>
                        Areas of Operation
                    </Typography>
                    <Typography textAlign={'center'} variant="h6" padding={1}>
                        We are located in Inver Grove Heights, Minnesota and
                        serve the surrounding areas. For larger projects, we are
                        willing to travel further. Please contact us for more
                        information!
                    </Typography>
                    <Box height={'50vh'} width={'75vw'} p={'2vh'}>
                        <Box></Box>
                        <FooterMap></FooterMap>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    textAlign={'center'}
                    variant="h3"
                    padding={1}
                    color={theme.palette.primary.textWhite}
                >
                    Snow Removal
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={12}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                color={theme.palette.primary.textWhite}
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Box padding={2}>
                            <ImageList cols={2}>
                                {snowRemovalList2.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <img
                                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                            alt={item.title}
                                            height={'100%'}
                                            width={'100%'}
                                            loading="eager"
                                            title={item.title}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Box padding={2} style={{ flex: 3 }}>
                            <ImageList cols={3}>
                                {snowRemovalList.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <img
                                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                            alt={item.title}
                                            height={'100%'}
                                            width={'100%'}
                                            loading="eager"
                                            title={item.title}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>
                        <Box padding={2} style={{ flex: 1 }}>
                            <Box
                                padding={2}
                                height={'100%'}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                            >
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    Driveways, Sidewalks, and Parking Lots
                                </Typography>
                                <Typography variant="body2">
                                    We provide comprehensive snow removal
                                    services for both commercial and residential
                                    properties. Our services include snow
                                    plowing, shoveling, and salting to ensure
                                    safe and accessible premises during the
                                    winter months.
                                </Typography>

                                <Box display={'flex'} flexDirection={'row'}>
                                    <Box padding={1}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                navigate('/SnowplowPricing')
                                            }}
                                        >
                                            View Pricing
                                        </Button>
                                    </Box>
                                    <Box padding={1}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .backup,
                                                color: theme.palette.primary
                                                    .textWhite,
                                            }}
                                            href={'/contactUs'}
                                        >
                                            Learn More
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'auto'}
                backgroundColor={theme.palette.primary.textWhite}
                color={theme.palette.primary.textBlack}
            >
                <Grid
                    container
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    padding={2}
                >
                    <Grid item xs={12}>
                        <Typography
                            textAlign={'center'}
                            variant="h3"
                            padding={1}
                        >
                            General Contracting
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        p={'1vh'}
                        width={'auto'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Box padding={1}>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1 }}
                                display={'flex'}
                            >
                                We are a fully licensed general contractor in
                                the state of Minnesota. We specialize in outdoor
                                activity areas such as firepits, outdoor A/V
                                systems, kitchens and more. Further, we're
                                experienced with and have built decks, sheds,
                                garages and other smaller home additions. We're
                                also experienced with interior remodeling and
                                can help you with your next project.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        lg={8}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <Grid item padding={1}>
                            <Card
                                sx={{
                                    maxWidth: 345,
                                    backgroundColor:
                                        theme.palette.primary.supportingWhite,
                                }}
                            >
                                <CardActionArea href={'/contactUs'}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        width={'100%'}
                                        loading="eager"
                                        title="Shed"
                                        image={shed}
                                        alt="Shed"
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            Wooden Structures
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Garages, sheds, decks, and more
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item padding={1}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea href={'/contactUs'}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        width={'100%'}
                                        loading="eager"
                                        title="Sidewalk"
                                        image={sidewalk}
                                        alt="Sidewalk"
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            Concrete
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Sidewalks, driveways, patios etc
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item padding={1}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea href={'/contactUs'}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={retaining_wall}
                                        width={'100%'}
                                        loading="eager"
                                        title="Retaining wall"
                                        alt="Retaining wall"
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            Masonry
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Retaining walls, brick, stone,
                                            patios, pavers...
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Grid
                    container
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    color={theme.palette.primary.textWhite}
                    padding={2}
                >
                    <Grid item xs={12}>
                        <Typography
                            textAlign={'center'}
                            variant="h3"
                            padding={1}
                        >
                            Landscaping
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <Grid item padding={1}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea href={'/contactUs'}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={backyard}
                                        width={'100%'}
                                        loading="eager"
                                        title="Design"
                                        alt="Design"
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            Landscape Design and Irrigation
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            We can help you design and renovate
                                            your outdoor space. We offer
                                            irrigation installation and repair,
                                            landscaping design, and more.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item padding={1}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea href={'/contactUs'}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={arborvitae}
                                        width={'100%'}
                                        loading="eager"
                                        title="Tree Install"
                                        alt="Tree Install"
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            Tree Consultation and Installation
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Our tree experts can provide you
                                            with the perfect tree for your
                                            landscape. We also offer tree
                                            consultation and installation.
                                            Contact us for more information!
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid padding={1}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea href={'/contactUs'}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={mulch}
                                        width={'100%'}
                                        loading="eager"
                                        title="Mulch"
                                        alt="Mulch"
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            Lawn Improvements and Upgrades
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            We offer aeration, mulching,
                                            planting, sodding, seeding to name a
                                            few!
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    backgroundColor={theme.palette.primary.textWhite}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        paddingTop={4}
                        paddingBottom={4}
                        paddingLeft={2}
                        paddingRight={2}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <AccessibilityNew />
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        textAlign={'center'}
                                        padding={1}
                                        sx={{ flexGrow: 1 }}
                                        color={theme.palette.primary.textBlack}
                                    >
                                        That and more!
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        textAlign={'center'}
                                        padding={1}
                                        sx={{ flexGrow: 1 }}
                                        color={theme.palette.primary.textBlack}
                                    >
                                        We're happy to help with any of your
                                        property management needs. Contact us
                                        for more information!
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={12}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Button
                                        variant={'contained'}
                                        href={'/contactUs'}
                                    >
                                        Contact Us
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}
