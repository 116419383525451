//This will be the component that holds the customer info for the contractor view on the customer detail screen.
// This should be a read only view, with a popover that allows for editing of the customer object.
//It should accept in a customer object.

//It should have the following fields:
// - First Name as first_name
// - Last Name as last_name
// - Address as address
// - Phone Number as phone_number
// - Email as email
// - User ID as user_id
// - Customer Sub as customer_sub
//- Role as role
// - Property Notes as property_notes

//It should have a button that allows for editing of the customer object.

import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { Typography, Button, Grid, Box, IconButton } from '@mui/material'
import CustomPopover from '../../../modals/CustomPopover/CustomPopover'
import EditCustomerContractorView from './EditCustomerContractorView'
import theme from '../../../../theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ExpandLess } from '@mui/icons-material'
import Success from '../../FormStatusIcons/Success'

export default function CustomerInfoContractorView(props) {
    const { customer, getContractorDashboard, handleToggle } = props
    const [editCustomerPopoverVisible, setEditCustomerPopoverVisible] =
        useState(false)
    const [metadataExpanded, setMetadataExpanded] = useState(false)
    const [confirmDropCustomer, setConfirmDropCustomer] = useState(false)
    const [confirmDeleteCustomer, setConfirmDeleteCustomer] = useState(false)
    const [customerToDrop, setCustomerToDrop] = useState(null)
    const [customerToDelete, setCustomerToDelete] = useState(null)
    const [statusMessage, setStatusMessage] = useState('')
    const { getAccessTokenSilently } = useAuth0()

    const executeDropCustomer = async () => {
        const accessToken = await getAccessTokenSilently()

        //An axios route to set the owned_by_contractor_user_id to null
        customerToDrop &&
            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/removeCustomer`,
                    { customerID: customerToDrop.user_id },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    setStatusMessage('Customer Dropped')
                    setTimeout(() => {
                        setStatusMessage('')
                        setConfirmDropCustomer(false)
                        handleToggle('View Customers')
                    }, 1000)
                })
    }

    const executeDeleteCustomer = async () => {
        const accessToken = await getAccessTokenSilently()

        customerToDelete &&
            axios
                .delete(
                    `${process.env.REACT_APP_BACKEND_DB}/deleteCustomer/` +
                        customerToDelete.user_id,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    setStatusMessage('Customer Deleted')
                    setTimeout(() => {
                        setStatusMessage('')
                        setConfirmDeleteCustomer(false)
                        handleToggle('View Customers')
                    }, 1000)
                })
    }

    const handleDropCustomer = (customerID) => {
        setConfirmDropCustomer(true)
        setCustomerToDrop(customerID)
    }

    const handleDeleteCustomer = (customerID) => {
        setConfirmDeleteCustomer(true)
        setCustomerToDelete(customerID)
    }

    return (
        <Grid container>
            {editCustomerPopoverVisible && (
                <CustomPopover
                    setPopoverVisible={setEditCustomerPopoverVisible}
                >
                    <EditCustomerContractorView
                        customer={customer}
                        editCustomerPopoverVisible={editCustomerPopoverVisible}
                        setEditCustomerPopover={setEditCustomerPopoverVisible}
                        getContractorDashboard={getContractorDashboard}
                    />
                </CustomPopover>
            )}
            {confirmDropCustomer && (
                <CustomPopover setPopoverVisible={setConfirmDropCustomer}>
                    <Box>
                        <Typography variant="h6">Drop Customer?</Typography>
                        {statusMessage && (
                            <Box p={1}>
                                <Success title={statusMessage}></Success>
                            </Box>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={executeDropCustomer}
                        >
                            Confirm Drop
                        </Button>
                    </Box>
                </CustomPopover>
            )}
            {confirmDeleteCustomer && (
                <CustomPopover setPopoverVisible={setConfirmDeleteCustomer}>
                    <Box>
                        <Typography variant="h6">Delete Customer?</Typography>
                        {statusMessage && (
                            <Box p={1}>
                                <Success title={statusMessage}></Success>
                            </Box>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={executeDeleteCustomer}
                        >
                            Confirm Delete
                        </Button>
                    </Box>
                </CustomPopover>
            )}
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    sx={{
                        color: theme.palette.primary.textWhite,
                        fontSize: '2rem',
                        fontWeight: 'title',
                    }}
                >
                    {customer?.first_name + ' ' + customer?.last_name}
                </Typography>
            </Grid>

            <Grid item xs={12} container>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row">
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.primary.textWhite,
                                fontSize: '1.25rem',
                                fontWeight: 'regular',
                            }}
                        >
                            {customer?.address || 'N/A'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: theme.palette.primary.textWhite,
                            fontSize: '1.25rem',
                            fontWeight: 'regular',
                        }}
                    >
                        {customer?.phone_number || 'N/A'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: theme.palette.primary.textWhite,
                            fontSize: '1.25rem',
                            fontWeight: 'regular',
                        }}
                    >
                        {customer?.email || 'N/A'}
                    </Typography>
                </Grid>
                <Grid item xs={12} display={'flex'} flexDirection={'column'}>
                    <Box display="flex" flexDirection="row">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    color: theme.palette.primary.textWhite,
                                    fontSize: '1rem',
                                    fontWeight: 'light',
                                }}
                            >
                                Metadata
                            </Typography>
                        </Box>
                        <IconButton
                            onClick={() =>
                                setMetadataExpanded(!metadataExpanded)
                            }
                            sx={{
                                color: theme.palette.primary.textWhite,
                            }}
                        >
                            {metadataExpanded ? (
                                <ExpandLess
                                    sx={{
                                        fontSize: '1rem',
                                        color: theme.palette.primary.textWhite,
                                    }}
                                />
                            ) : (
                                <ExpandMoreIcon
                                    sx={{
                                        fontSize: '1rem',
                                        color: theme.palette.primary.textWhite,
                                    }}
                                />
                            )}
                        </IconButton>
                    </Box>
                    {metadataExpanded && (
                        <>
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="row">
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'regular',
                                        }}
                                        paddingRight={1}
                                    >
                                        Sub:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'light',
                                        }}
                                    >
                                        {customer?.customer_sub || 'N/A'}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="row">
                                    <Typography
                                        variant="h6"
                                        paddingRight={1}
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'regular',
                                        }}
                                    >
                                        Role:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'light',
                                        }}
                                    >
                                        {customer?.role || 'N/A'}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography
                                        variant="h6"
                                        paddingRight={1}
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'regular',
                                        }}
                                    >
                                        ID #:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'light',
                                        }}
                                    >
                                        {customer?.user_id}
                                    </Typography>
                                </Box>
                            </Grid>
                            {customer?.preferences?.contact_method && (
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="row">
                                        <Typography
                                            variant="h6"
                                            paddingRight={1}
                                            sx={{
                                                color: theme.palette.primary
                                                    .textWhite,
                                                fontSize: '1rem',
                                                fontWeight: 'regular',
                                            }}
                                        >
                                            Contact Method:
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: theme.palette.primary
                                                    .textWhite,
                                                fontSize: '1rem',
                                                fontWeight: 'light',
                                            }}
                                        >
                                            {
                                                customer?.preferences
                                                    ?.contact_method
                                            }
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                            {customer?.preferences?.auto_pay_enabled && (
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="row">
                                        <Typography
                                            variant="h6"
                                            paddingRight={1}
                                            sx={{
                                                color: theme.palette.primary
                                                    .textWhite,
                                                fontSize: '1rem',
                                                fontWeight: 'regular',
                                            }}
                                        >
                                            Auto Pay Enabled:
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: theme.palette.primary
                                                    .textWhite,
                                                fontSize: '1rem',
                                                fontWeight: 'light',
                                            }}
                                        >
                                            {
                                                customer?.preferences
                                                    ?.auto_pay_enabled
                                            }
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                            {customer?.preferences?.route_notifications && (
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="row">
                                        <Typography
                                            variant="h6"
                                            paddingRight={1}
                                            sx={{
                                                color: theme.palette.primary
                                                    .textWhite,
                                                fontSize: '1rem',
                                                fontWeight: 'regular',
                                            }}
                                        >
                                            Route Notifications:
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: theme.palette.primary
                                                    .textWhite,
                                                fontSize: '1rem',
                                                fontWeight: 'light',
                                            }}
                                        >
                                            {
                                                customer?.preferences
                                                    ?.route_notifications
                                            }
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                //center items
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box pr={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditCustomerPopoverVisible(true)}
                    >
                        Edit Customer
                    </Button>
                </Box>
                <Box pr={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDropCustomer(customer)}
                    >
                        Drop Customer
                    </Button>
                </Box>
                <Box pr={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDeleteCustomer(customer)}
                    >
                        Delete Customer
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
