import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Box } from '@mui/system'
import { Button, Typography } from '@mui/material'
import Info from '../../FormStatusIcons/Info'
import { FormControl } from '@mui/base'
import ReactGA from 'react-ga4'
import { useTheme } from '@mui/material/styles'

import CircularProgress from '@mui/material/CircularProgress'

export default function CheckoutForm(props) {
    const stripe = useStripe()
    const elements = useElements()
    const { clientSecret, returnURL } = props
    const theme = useTheme()

    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        const fetchPaymentIntent = async () => {
            setIsLoading(true)
            if (!stripe) {
                return
            }

            if (!clientSecret) {
                return
            }

            await stripe
                .retrievePaymentIntent(clientSecret)
                .then(({ paymentIntent }) => {
                    switch (paymentIntent.status) {
                        case 'succeeded':
                            setMessage('Payment succeeded!')
                            break
                        case 'processing':
                            setMessage('Your payment is processing.')
                            break
                        case 'requires_payment_method':
                            setMessage(
                                'Please enter your details to complete the payment.'
                            )
                            break
                        default:
                            setMessage('Something went wrong.')
                            break
                    }
                })

            ReactGA.event({
                category: 'Payment',
                action: 'Checkout Form Loaded',
            })

            setIsLoading(false)
        }
        fetchPaymentIntent()
    }, [stripe, clientSecret])

    const handleSubmit = async (e) => {
        ReactGA.event({
            category: 'Payment',
            action: 'Payment Submitted',
        })

        e.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsSubmitting(true)

        const response = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: returnURL,
            },
        })

        const { error } = response

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message)
        } else {
            setMessage('An unexpected error occurred.')
        }

        setIsSubmitting(false)
    }

    const paymentElementOptions = {
        layout: 'tabs',
    }

    return (
        <FormControl onSubmit={handleSubmit}>
            {!isLoading ? (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    {message ? (
                        <Box>
                            <PaymentElement
                                id="payment-element"
                                options={paymentElementOptions}
                            />
                            <Box padding={2}>
                                <Info title={message}></Info>{' '}
                            </Box>
                        </Box>
                    ) : (
                        //loading
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    <Box padding={2}>
                        {!isSubmitting ? (
                            <Button
                                sx={{
                                    backgroundColor:
                                        theme.palette.primary.backup,
                                    color: theme.palette.primary.textWhite,
                                }}
                                variant="contained"
                                disabled={isLoading || !stripe || !elements}
                                id="submit"
                                onClick={handleSubmit}
                            >
                                Pay now
                            </Button>
                        ) : (
                            <Typography variant="body1">
                                <Button
                                    sx={{
                                        backgroundColor:
                                            theme.palette.primary.backup,
                                        color: theme.palette.primary.textWhite,
                                    }}
                                    variant="contained"
                                    disabled
                                    id="paying"
                                >
                                    <CircularProgress size={30} />
                                </Button>
                            </Typography>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <CircularProgress />
                </Box>
            )}
        </FormControl>
    )
}
