//make a react component called EditChargePopover
//it should have a prop called charge
//a charge has the following sample details:
/*

Charge Details
Charge ID: 286
Charge Amount: $55.00
Service Date: 11/05/2024
Notes: Nonee

*/

import React, { useState } from 'react'
import {
    TextField,
    Typography,
    Button,
    Box,
    Grid,
    useTheme,
    CircularProgress,
} from '@mui/material'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import CustomPopover from '../CustomPopover/CustomPopover'
import dayjs from 'dayjs'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Success from '../../Components/FormStatusIcons/Success'
import Failed from '../../Components/FormStatusIcons/Failed'

export default function EditChargePopover({
    charge,
    setPopoverVisible,
    getContractorDashboard,
}) {
    const { getAccessTokenSilently } = useAuth0()
    const theme = useTheme()
    const [chargeAmount, setChargeAmount] = useState(charge?.amount)
    const [dateServiced, setDateServiced] = useState(
        dayjs(charge?.date_serviced)
    )
    const [servicePerformed, setServicePerformed] = useState(
        charge?.service_performed
    )

    const [formStatus, setFormStatus] = useState('idle')

    const handleSubmit = async () => {
        //make an axios call to /updateUserContractor with the updated customer object
        //then close the popover

        const accessToken = await getAccessTokenSilently()
        setFormStatus('loading')

        axios
            .post(
                process.env.REACT_APP_BACKEND_DB + '/updateCharge',
                {
                    charge_id: charge.charge_id,
                    amount: chargeAmount,
                    date_serviced: dateServiced,
                    service_performed: servicePerformed,
                    charge_status: charge.charge_status,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )

            .then((res) => {
                setFormStatus('success')
                setTimeout(() => {
                    setFormStatus('idle')
                    getContractorDashboard()
                    setPopoverVisible(false)
                }, 1000)
            })
            .catch((err) => {
                setFormStatus('error')
                console.error(err)
                setTimeout(() => {
                    setFormStatus('idle')
                })
            })
    }

    return (
        <CustomPopover
            title="Charge Details"
            setPopoverVisible={setPopoverVisible}
        >
            <Box p={2}>
                <Typography variant="h6" paddingBottom={2}>
                    Charge Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DatePicker
                            label="Date Serviced"
                            value={dateServiced}
                            onChange={(e) => setDateServiced(e.target.value)}
                            fullWidth
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Charge Amount"
                            value={chargeAmount}
                            onChange={(e) => setChargeAmount(e.target.value)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Service Performed"
                            value={servicePerformed}
                            onChange={(e) =>
                                setServicePerformed(e.target.value)
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box //display vertical
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                {formStatus === 'loading' ? (
                                    <CircularProgress
                                        size={theme.spacing(2)}
                                        color="inherit"
                                    />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                            {formStatus === 'error' && (
                                <Box p={1}>
                                    <Failed error="Failed to save charge" />
                                </Box>
                            )}
                            {formStatus === 'success' && (
                                <Box p={1}>
                                    <Success title="Charge saved" />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </CustomPopover>
    )
}
